import { createWebHistory, type RouteRecordRaw } from "vue-router";

import { createRouter } from "@tager/admin-layout";
import {
  ADMIN_FORM_ROUTE,
  ADMIN_LIST_ROUTE,
  ROLE_FORM_ROUTE,
  ROLE_LIST_ROUTE,
} from "@tager/admin-administrators";
import {
  PAGE_COMMANDS_ROUTE,
  PAGE_CRON_LOGS_ROUTE,
  PAGE_COMMANDS_LOGS_ROUTE,
  PAGE_COMMANDS_LOG_DETAILS_ROUTE,
  PAGE_CRON_LOG_DETAILS_ROUTE,
  PAGE_COMMANDS_DETAILS_ROUTE,
} from "@tager/admin-cron";

import {
  DEALERS_UPDATE_ROUTE,
  DEALERS_CREATE_ROUTE,
  DEALERS_LIST_ROUTE,
} from "@/modules/dealers";
import {
  LOCATIONS_LIST_ROUTE,
  LOCATIONS_CREATE_ROUTE,
  LOCATIONS_UPDATE_ROUTE,
} from "@/modules/locations";
import Home from "@/views/Home.vue";
import { REVIEWS_LIST_ROUTE } from "@/modules/reviews";

export const HOME_ROUTE: RouteRecordRaw = {
  path: "/",
  component: Home,
  name: "Home",
  meta: {
    getBreadcrumbs: (route, i18n) => [{ url: "/", text: i18n.t("admin:home") }],
  },
};

export const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH || "/"),
  routes: [
    HOME_ROUTE,

    ADMIN_LIST_ROUTE,
    ADMIN_FORM_ROUTE,
    ROLE_LIST_ROUTE,
    ROLE_FORM_ROUTE,

    PAGE_COMMANDS_ROUTE,
    PAGE_CRON_LOGS_ROUTE,
    PAGE_COMMANDS_LOGS_ROUTE,
    PAGE_COMMANDS_LOG_DETAILS_ROUTE,
    PAGE_CRON_LOG_DETAILS_ROUTE,
    PAGE_COMMANDS_DETAILS_ROUTE,

    DEALERS_UPDATE_ROUTE,
    DEALERS_CREATE_ROUTE,
    DEALERS_LIST_ROUTE,

    LOCATIONS_LIST_ROUTE,
    LOCATIONS_CREATE_ROUTE,
    LOCATIONS_UPDATE_ROUTE,

    REVIEWS_LIST_ROUTE,
  ],
});
