import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row-cols-3" }
const _hoisted_2 = { class: "row-cols-2" }
const _hoisted_3 = { class: "row-cols-2" }
const _hoisted_4 = { class: "row-cols-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_FormFieldMultiSelect = _resolveComponent("FormFieldMultiSelect")!
  const _component_FormFieldFileInput = _resolveComponent("FormFieldFileInput")!
  const _component_DynamicField = _resolveComponent("DynamicField")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    "tab-id": _ctx.selectedTabId,
    "onUpdate:tab-id": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.selectedTabId) = $event)),
    title: 'Редактировать Локацию ID ' + _ctx.id,
    "is-content-loading": _ctx.isLoading,
    tabs: _ctx.tabList
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "back-href": _ctx.backButtonUrl,
        "is-submitting": _ctx.isSubmitting,
        "is-creation": false,
        "can-create-another": false,
        onSubmit: _ctx.submitForm
      }, null, 8, ["back-href", "is-submitting", "onSubmit"])
    ]),
    default: _withCtx(() => [
      (_ctx.values)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            novalidate: "",
            onSubmit: _cache[21] || (_cache[21] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createElementVNode("div", {
              style: _normalizeStyle({ display: _ctx.selectedTabId === 'common' ? 'block' : 'none' })
            }, [
              _createVNode(_component_FormField, {
                value: _ctx.values.name,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.name) = $event)),
                name: "name",
                error: _ctx.errors.name,
                label: "Имя"
              }, null, 8, ["value", "error"]),
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_FormFieldSelect, {
                  value: _ctx.values.dealer,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.dealer) = $event)),
                  name: "dealer",
                  error: _ctx.errors.dealer,
                  label: "Дилер",
                  options: _ctx.dealersOptions
                }, null, 8, ["value", "error", "options"]),
                _createVNode(_component_FormFieldSelect, {
                  value: _ctx.values.dealerCrm,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.dealerCrm) = $event)),
                  name: "dealerCrm",
                  error: _ctx.errors.dealerCrm,
                  label: "Дилер - Cвязка с CRM",
                  options: _ctx.dealersOptions
                }, null, 8, ["value", "error", "options"]),
                _createVNode(_component_FormFieldSelect, {
                  value: _ctx.values.apiId,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.apiId) = $event)),
                  name: "apiId",
                  error: _ctx.errors.apiId,
                  label: "API Связка",
                  options: _ctx.apiOptions
                }, null, 8, ["value", "error", "options"])
              ]),
              _createVNode(_component_FormFieldMultiSelect, {
                "selected-options": _ctx.values.brands,
                "onUpdate:selected-options": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.values.brands) = $event)),
                name: "brands",
                error: _ctx.errors.brands,
                label: "Бренды",
                options: _ctx.brandsOptions
              }, null, 8, ["selected-options", "error", "options"])
            ], 4),
            _createElementVNode("div", {
              style: _normalizeStyle({ display: _ctx.selectedTabId === 'content' ? 'block' : 'none' })
            }, [
              _createVNode(_component_FormFieldFileInput, {
                value: _ctx.values.image,
                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.values.image) = $event)),
                error: _ctx.errors.image,
                label: "Картинка",
                name: "icon",
                "file-type": "image",
                scenario: "dealer-image"
              }, null, 8, ["value", "error"]),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_FormField, {
                  value: _ctx.values.phone,
                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.values.phone) = $event)),
                  name: "phone",
                  error: _ctx.errors.phone,
                  label: "Телефон"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.phoneStock,
                  "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.values.phoneStock) = $event)),
                  name: "phoneStock",
                  error: _ctx.errors.phoneStock,
                  label: "Телефон на складе"
                }, null, 8, ["value", "error"])
              ]),
              _createVNode(_component_FormField, {
                value: _ctx.values.address,
                "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.values.address) = $event)),
                name: "address",
                error: _ctx.errors.address,
                label: "Адрес"
              }, null, 8, ["value", "error"]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_FormField, {
                  value: _ctx.values.latitude,
                  "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.values.latitude) = $event)),
                  name: "latitude",
                  error: _ctx.errors.latitude,
                  label: "Широта",
                  type: "number"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.longitude,
                  "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.values.longitude) = $event)),
                  name: "longitude",
                  error: _ctx.errors.longitude,
                  label: "Долгота",
                  type: "number"
                }, null, 8, ["value", "error"])
              ])
            ], 4),
            _createElementVNode("div", {
              style: _normalizeStyle({ display: _ctx.selectedTabId === 'social' ? 'block' : 'none' })
            }, [
              _createVNode(_component_FormField, {
                value: _ctx.values.website,
                "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.values.website) = $event)),
                name: "website",
                error: _ctx.errors.website,
                label: "Веб-Сайт"
              }, null, 8, ["value", "error"]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_FormField, {
                  value: _ctx.values.instagram,
                  "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.values.instagram) = $event)),
                  name: "instagram",
                  error: _ctx.errors.instagram,
                  label: "Instagram"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.odnoklassniki,
                  "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.values.odnoklassniki) = $event)),
                  name: "odnoklassniki",
                  error: _ctx.errors.odnoklassniki,
                  label: "Odnoklassniki"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.facebook,
                  "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.values.facebook) = $event)),
                  name: "facebook",
                  error: _ctx.errors.facebook,
                  label: "Facebook"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.vkontakte,
                  "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.values.vkontakte) = $event)),
                  name: "vkontakte",
                  error: _ctx.errors.vkontakte,
                  label: "ВКонтакте"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.youtube,
                  "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.values.youtube) = $event)),
                  name: "youtube",
                  error: _ctx.errors.youtube,
                  label: "Youtube"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.telegram,
                  "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.values.telegram) = $event)),
                  name: "telegram",
                  error: _ctx.errors.telegram,
                  label: "Telegram"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.viber,
                  "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.values.viber) = $event)),
                  name: "viber",
                  error: _ctx.errors.viber,
                  label: "Viber"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.skype,
                  "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.values.skype) = $event)),
                  name: "skype",
                  error: _ctx.errors.skype,
                  label: "Skype"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.tiktok,
                  "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.values.tiktok) = $event)),
                  name: "tiktok",
                  error: _ctx.errors.tiktok,
                  label: "TikTok"
                }, null, 8, ["value", "error"])
              ])
            ], 4),
            _createElementVNode("div", {
              style: _normalizeStyle({ display: _ctx.selectedTabId === 'departments' ? 'block' : 'none' })
            }, [
              _createVNode(_component_DynamicField, { field: _ctx.divisionsField }, null, 8, ["field"])
            ], 4)
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["tab-id", "title", "is-content-loading", "tabs"]))
}