
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  FormFooter,
  TagerFormSubmitEvent,
  FormField,
  OptionType,
  FormFieldSelect,
  FormFieldFileInput,
  FormFieldMultiSelect,
  TabType,
  createTabErrorFinder,
} from "@tager/admin-ui";
import {
  FieldUnion,
  RepeaterField,
  universalFieldUtils,
  DynamicField,
  RepeaterIncomingValue,
} from "@tager/admin-dynamic-field";

import {
  getDealerLocation,
  getLocationsUpdateUrl,
  getLocationsUrl,
  updateDealerLocation,
} from "@/modules/locations";
import { DealerLocationFullInterface } from "@/modules/locations/typings";
import { getApiDealers, getBrands, getDealers } from "@/modules/dealers";
import { DealerInterface } from "@/modules/dealers/typings";

import {
  FormValues,
  getDealerFormValue,
  convertPageFormValuesToUpdatePayload,
  DIVISIONS_FIELD_REPEATER_CONFIG,
} from "./LocationsUpdate.helpers";

export default defineComponent({
  name: "LocationsUpdate",
  components: {
    FormFieldMultiSelect,
    FormFieldFileInput,
    FormFieldSelect,
    Page,
    FormField,
    FormFooter,

    DynamicField,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const id = computed(() => route.params.id as string);

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () => {
        if (id.value) {
          return getDealerLocation(id.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "Dealer Location",
    });

    const [fetchDealersList, { data: dealers, loading: isDealersLoading }] =
      useResource({
        fetchResource: () => getDealers(),
        initialValue: [],
        resourceName: "Dealers",
      });

    const [
      fetchApiDealers,
      { data: apiDealers, loading: isApiDealersLoading },
    ] = useResource({
      fetchResource: getApiDealers,
      initialValue: [],
      resourceName: "Api Dealers",
    });

    const [fetchBrands, { data: brands, loading: isBrandsLoading }] =
      useResource({
        fetchResource: getBrands,
        initialValue: [],
        resourceName: "Brands",
      });

    onMounted(() => {
      fetchModel();
      fetchDealersList();
      fetchApiDealers();
      fetchBrands();
    });

    watch(id, fetchModel);

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>();

    const isSubmitting = ref<boolean>(false);

    const dealersOptions = computed<Array<OptionType<number>>>(() => {
      return [
        ...dealers.value.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      ];
    });

    const getApiOptions = (): Array<OptionType<number>> => {
      const selectedDealer = dealers.value.find(
        (dealer) => dealer.id === values.value?.dealer?.value
      );

      if (!selectedDealer?.apiId) {
        return [];
      }

      const apiDealer = apiDealers.value.find(
        (item) => item.id === selectedDealer.apiId
      );

      if (!apiDealer) {
        return [];
      }

      return apiDealer.locations.map((item) => ({
        value: item.id,
        label: "ID " + item.id + " - " + item.name,
      }));
    };

    const selectedDealerId = computed(() => values.value?.dealer?.value);
    const selectedDealer = computed<DealerInterface | null>(
      () =>
        dealers.value?.find((item) => item.id === selectedDealerId?.value) ||
        null
    );

    const apiOptions = computed<Array<OptionType<number>>>(getApiOptions);
    const apiOptionsInitialized = ref<boolean>(false);

    watch([selectedDealerId, isApiDealersLoading], () => {
      if (!values.value || !selectedDealerId.value || isApiDealersLoading.value)
        return;

      if (apiOptionsInitialized.value) {
        values.value.apiId = null;
        return;
      }

      values.value.apiId =
        getApiOptions().find((item) => item.value === model.value?.apiId) ||
        null;

      apiOptionsInitialized.value = true;
    });

    const brandOptionsInitialized = ref<boolean>(false);

    const getBrandsOptions = () => {
      const availableBrandIds = selectedDealer.value?.brandIds || [];

      return [
        ...brands.value
          .filter((item) => availableBrandIds?.includes(item.id))
          .map((item) => ({
            value: item.id,
            label: item.name,
          })),
      ];
    };

    const brandsOptions = computed<Array<OptionType<number>>>(getBrandsOptions);

    watch([selectedDealerId, isBrandsLoading], () => {
      if (!values.value || !selectedDealerId.value || isBrandsLoading.value)
        return;

      if (brandOptionsInitialized.value) {
        values.value.brands = [];
        return;
      }

      values.value.brands = getBrandsOptions().filter((item) =>
        model.value?.brandIds?.includes(item.value)
      );

      brandOptionsInitialized.value = true;
    });

    const divisionsField = ref<FieldUnion>(
      universalFieldUtils.createFormField(DIVISIONS_FIELD_REPEATER_CONFIG, null)
    );

    function updateDivisionsField() {
      const incomingFieldList: RepeaterIncomingValue = model.value?.divisions
        ? model.value?.divisions.map((value) => {
            return [
              {
                name: "name",
                value: value.name,
              },
              {
                name: "image",
                value: value.image,
              },
              {
                name: "phone",
                value: value.phone,
              },
              {
                name: "workTime",
                value: value.workTime,
              },
            ];
          })
        : [];

      divisionsField.value = universalFieldUtils.createFormField(
        DIVISIONS_FIELD_REPEATER_CONFIG,
        incomingFieldList
      ) as RepeaterField;
    }

    function updateFormValues() {
      values.value = getDealerFormValue(
        model.value as DealerLocationFullInterface,
        dealersOptions.value
      );
    }

    watch([model], () => {
      if (model.value) {
        updateFormValues();
        updateDivisionsField();
      }

      fetchDealersList();
    });

    watch([dealersOptions], () => {
      updateFormValues();
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const requestPromise = updateDealerLocation(
        id.value,
        convertPageFormValuesToUpdatePayload(
          values.value as FormValues,
          divisionsField.value as RepeaterField
        )
      );

      requestPromise
        .then((response: any) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getLocationsUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getLocationsUrl());
          }
          toast.show({
            variant: "success",
            title: "Локации",
            body: "Локация успешно обновлена",
          });
        })
        .catch((error: any) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Локации",
            body: "Ошибка изменения локации",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isLoading = computed<boolean>(
      () =>
        isModelLoading.value ||
        isDealersLoading.value ||
        isApiDealersLoading.value ||
        isBrandsLoading.value
    );

    const tabList = computed<Array<TabType>>(() => {
      const hasErrors = createTabErrorFinder(errors.value);

      return [
        {
          id: "common",
          label: "Основное",
          hasErrors: hasErrors(["name"]),
        },
        {
          id: "content",
          label: "Контент",
        },
        {
          id: "social",
          label: "Соц. сети",
        },
        {
          id: "departments",
          label: "Подразделения",
        },
      ];
    });

    const selectedTabId = ref<string>(tabList.value[0].id);

    return {
      tabList,
      selectedTabId,
      id,
      isLoading,
      values,
      errors,
      model,
      submitForm,
      backButtonUrl: getLocationsUrl(),
      isSubmitting,

      dealersOptions,
      apiOptions,
      brandsOptions,

      divisionsField,
    };
  },
});
