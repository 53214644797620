
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  FormFooter,
  TagerFormSubmitEvent,
  FormField,
  OptionType,
  FormFieldSelect,
} from "@tager/admin-ui";

import {
  createDealerLocation,
  getLocationsUpdateUrl,
  getLocationsUrl,
} from "@/modules/locations";
import { getApiDealers, getDealers } from "@/modules/dealers";

import {
  FormValues,
  convertPageFormValuesToCreationPayload,
  getDefaultFormValues,
} from "./LocationsCreate.helpers";

export default defineComponent({
  name: "DealersCreate",
  components: { Page, FormField, FormFooter, FormFieldSelect },
  setup() {
    const router = useRouter();
    const toast = useToast();

    const [
      fetchApiDealers,
      { data: apiDealers, loading: isApiDealersLoading },
    ] = useResource({
      fetchResource: getApiDealers,
      initialValue: [],
      resourceName: "Api Dealers",
    });

    const [fetchDealersList, { data: dealers, loading: isDealersLoading }] =
      useResource({
        fetchResource: () => getDealers(),
        initialValue: [],
        resourceName: "Dealers",
      });

    const dealersOptions = computed<Array<OptionType<number>>>(() => {
      return [
        ...dealers.value.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      ];
    });

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>(getDefaultFormValues());

    onMounted(() => {
      fetchDealersList();
      fetchApiDealers();
      values.value = getDefaultFormValues();
    });

    const isSubmitting = ref<boolean>(false);

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const requestPromise = createDealerLocation(
        convertPageFormValuesToCreationPayload(values.value)
      );

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getLocationsUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getLocationsUrl());
          }

          if (event.type === "create_create-another") {
            values.value = getDefaultFormValues();
          }

          toast.show({
            variant: "success",
            title: "Локации",
            body: "Локация успешно создана",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Дилеры",
            body: "Ошибка добавления локации",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isLoading = computed<boolean>(
      () => isDealersLoading.value || isApiDealersLoading.value
    );

    const apiOptions = computed<Array<OptionType<number>>>(() => {
      const selectedDealer = dealers.value.find(
        (dealer) => dealer.id === values.value.dealer?.value
      );

      if (!selectedDealer?.apiId) {
        return [];
      }

      const apiDealer = apiDealers.value.find(
        (item) => item.id === selectedDealer.apiId
      );

      if (!apiDealer) {
        return [];
      }

      return apiDealer.locations.map((item) => ({
        value: item.id,
        label: "ID " + item.id + " - " + item.name,
      }));
    });

    const computedDealer = computed<number | null>(
      () => values.value.dealer?.value || null
    );
    watch([computedDealer], () => {
      values.value.apiId = null;
    });

    return {
      isLoading,
      values,
      errors,
      submitForm,
      backButtonUrl: getLocationsUrl(),
      isSubmitting,
      dealersOptions,
      apiOptions,
    };
  },
});
