export function getDealersUrl(): string {
  return "/dealers";
}

export function getDealersCreateUrl(): string {
  return "/dealers/create";
}

export function getDealersUpdateUrl(id: number): string {
  return "/dealers/" + id;
}
