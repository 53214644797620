import { LinkType } from "@tager/admin-ui";
import { getAdminListUrl, getRoleListUrl } from "@tager/admin-administrators";
import {
  getCommandsListUrl,
  getCronLogsUrl,
  getCommandsLogsUrl,
} from "@tager/admin-cron";

import { getDealersUrl } from "@/modules/dealers";
import { getLocationsUrl } from "@/modules/locations";
import { getReviewsUrl } from "@/modules/reviews";

type LinkKey =
  | "HOME"
  | "DEALERS"
  | "LOCATIONS"
  | "REVIEWS"
  | "ADMIN_ROLES"
  | "ADMIN_ADMINS"
  | "CRON_COMMANDS"
  | "CRON_LOGS"
  | "CRON_COMMANDS_LOGS";

export const getLinks = (): Readonly<Record<LinkKey, LinkType>> => ({
  HOME: {
    url: "/",
    text: "Главная",
  },
  DEALERS: {
    url: getDealersUrl(),
    text: "Дилеры",
  },
  LOCATIONS: {
    url: getLocationsUrl(),
    text: "Локации",
  },
  REVIEWS: {
    url: getReviewsUrl(),
    text: "Отзывы",
  },
  CRON_COMMANDS: {
    url: getCommandsListUrl(),
    text: "Команды",
  },
  CRON_COMMANDS_LOGS: {
    url: getCommandsLogsUrl(),
    text: "Команды - Логи",
  },
  CRON_LOGS: {
    url: getCronLogsUrl(),
    text: "Cron Логи",
  },
  ADMIN_ROLES: {
    url: getRoleListUrl(),
    text: "Роли",
  },
  ADMIN_ADMINS: {
    url: getAdminListUrl(),
    text: "Администраторы",
  },
});
