import { ColumnDefinition } from "@tager/admin-ui";

import { ReviewInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<ReviewInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: { width: "50px", textAlign: "center" },
    headStyle: { width: "50px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 3,
    name: "Дата",
    field: "date",
    width: "120px",
  },
  {
    id: 3,
    name: "Дилер",
    field: "dealer",
    width: "150px",
  },
  {
    id: 4,
    name: "Оценка",
    field: "rating",
    style: { width: "50px", textAlign: "center" },
    headStyle: { width: "50px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 5,
    name: "Отзыв",
    field: "body",
  },
  {
    id: 6,
    name: "Ответ",
    field: "answer",
  },
];
