import { ColumnDefinition } from "@tager/admin-ui";

import { getLocationsUpdateUrl } from "@/modules/locations";

import { DealerLocationInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<DealerLocationInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: { width: "50px", textAlign: "center" },
    headStyle: { width: "50px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "API ID",
    field: "apiId",
    style: { width: "70px", textAlign: "center" },
    headStyle: { width: "70px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 3,
    name: "Название",
    field: "name",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: row.name,
        url: getLocationsUpdateUrl(row.id),
      },
      paramList: [
        {
          name: "Дилер",
          value: row.dealer,
        },
      ],
    }),
  },
  {
    id: 4,
    name: "Картинка",
    field: "image",
    type: "image",
    width: "200px",
  },
  {
    id: 5,
    name: "Адрес",
    field: "address",
  },
  {
    id: 5,
    name: "Действия",
    field: "actions",
    style: { width: "180px", textAlign: "center" },
    headStyle: { width: "180px", textAlign: "center", whiteSpace: "nowrap" },
  },
];
