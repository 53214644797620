import { MoveResource, request, ResponseBody } from "@tager/admin-services";

import { DealerInterface, DealerFullInterface } from "./typings";

export interface CountData {
  count: number;
}

export function getDealersCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/dealers/count" });
}

export function getDealers(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<DealerInterface>>> {
  return request.get({ path: "/admin/dealers", params });
}

export function getDealer(
  id: string | number
): Promise<ResponseBody<DealerFullInterface>> {
  return request.get({ path: `/admin/dealers/${id}` });
}

export type DealerCreatePayload = {
  name: string;
  apiDealer: number | null;
};

export function createDealer(
  payload: DealerCreatePayload
): Promise<ResponseBody<DealerFullInterface>> {
  return request.post({
    path: `/admin/dealers`,
    body: payload,
  });
}

export type DealerUpdatePayload = {
  type: string;
  name: string;
  shortDescription: string;
  apiDealer: number | null;
  urlSlug: string | null;
  brands: number[];
  hideVin: boolean;
  hidePrices: boolean;

  legalName: string | null;
  image: string | null;
  pageTitle: string | null;
  pageDescription: string | null;
  seoText: string | null;
  disablePartsOrder: boolean;
};

export function updateDealer(
  id: number | string,
  payload: DealerUpdatePayload
): Promise<ResponseBody<DealerFullInterface>> {
  return request.put({
    path: `/admin/dealers/${id}`,
    body: payload,
  });
}

export function deleteDealer(
  id: number | string
): Promise<{ success: boolean }> {
  return request.delete({ path: `/admin/dealers/${id}` });
}

export const moveDealer: MoveResource = (id, direction) => {
  return request.post({ path: `/admin/dealers/${id}/move/${direction}` });
};

type ApiDealer = {
  id: number;
  name: string;
  locations: Array<{
    id: number;
    name: string;
  }>;
};

export function getApiDealers(): Promise<ResponseBody<Array<ApiDealer>>> {
  return request.get({ path: "/admin/dealers/api-dealers" });
}

type Brand = {
  id: number;
  name: string;
};

export function getBrands(): Promise<ResponseBody<Array<Brand>>> {
  return request.get({ path: "/admin/dealers/brands" });
}
