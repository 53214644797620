import { OptionType, SingleFileInputValueType } from "@tager/admin-ui";
import { createId, Nullable } from "@tager/admin-services";

import { DealerFullInterface, DealerType } from "@/modules/dealers/typings";
import { DealerUpdatePayload } from "@/modules/dealers/requests";

export type FormValues = {
  type: DealerType;
  name: string;
  shortDescription: string;
  urlSlug: string;
  apiDealer: Nullable<OptionType<number>>;
  brands: Array<OptionType<number>>;
  hideVin: boolean;
  hidePrices: boolean;

  legalName: string | null;
  image: Nullable<SingleFileInputValueType>;
  pageTitle: string | null;
  pageDescription: string | null;
  seoText: string | null;
  disablePartsOrder: boolean;
};

export function getDealerFormValue(
  dealer: DealerFullInterface,
  apiDealersOptions: Array<OptionType<number>>,
  brandsOptions: Array<OptionType<number>>
): FormValues {
  return {
    type: dealer.type,
    name: dealer.name || "",
    shortDescription: dealer.shortDescription || "",
    urlSlug: dealer.urlSlug || "",
    apiDealer:
      apiDealersOptions.find((item) => item.value === dealer.apiDealer) || null,
    brands:
      brandsOptions?.filter((item) => dealer.brands?.includes(item.value)) ||
      [],
    hideVin: dealer.hideVin,
    hidePrices: dealer.hidePrices,

    legalName: dealer.legalName,
    image: dealer.image
      ? {
          id: createId(),
          file: dealer.image,
          caption: "",
        }
      : null,

    pageTitle: dealer.pageTitle,
    pageDescription: dealer.pageDescription,
    seoText: dealer.seoText,
    disablePartsOrder: dealer.disablePartsOrder,
  };
}

export function convertPageFormValuesToUpdatePayload(
  values: FormValues
): DealerUpdatePayload {
  return {
    type: values.type,
    name: values.name,
    shortDescription: values.shortDescription,
    urlSlug: values.urlSlug,
    apiDealer: values.apiDealer?.value || null,
    brands: values.brands.map((item) => item.value),
    hideVin: values.hideVin,
    hidePrices: values.hidePrices,

    legalName: values.legalName,
    image: values.image?.file.id || null,
    pageTitle: values.pageTitle,
    pageDescription: values.pageDescription,
    seoText: values.seoText,
    disablePartsOrder: values.disablePartsOrder,
  };
}
