import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: "Добавить локацию",
    "is-content-loading": _ctx.isLoading
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "back-href": _ctx.backButtonUrl,
        "is-submitting": _ctx.isSubmitting,
        "is-creation": true,
        "can-create-another": true,
        onSubmit: _ctx.submitForm
      }, null, 8, ["back-href", "is-submitting", "onSubmit"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        novalidate: "",
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createVNode(_component_FormField, {
          value: _ctx.values.name,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.name) = $event)),
          name: "name",
          error: _ctx.errors.name,
          label: "Имя"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormFieldSelect, {
          value: _ctx.values.dealer,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.dealer) = $event)),
          name: "dealer",
          error: _ctx.errors.dealer,
          label: "Дилер",
          options: _ctx.dealersOptions
        }, null, 8, ["value", "error", "options"]),
        _createVNode(_component_FormFieldSelect, {
          value: _ctx.values.apiId,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.apiId) = $event)),
          name: "apiId",
          error: _ctx.errors.apiId,
          label: "API Связка",
          options: _ctx.apiOptions
        }, null, 8, ["value", "error", "options"])
      ], 32)
    ]),
    _: 1
  }, 8, ["is-content-loading"]))
}