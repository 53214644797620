export function getLocationsUrl(): string {
  return "/locations";
}

export function getLocationsCreateUrl(): string {
  return "/locations/create";
}

export function getLocationsUpdateUrl(id: number): string {
  return "/locations/" + id;
}
