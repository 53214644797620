
import { defineComponent } from "vue";

import {
  useDataTable,
  DataTable,
  BaseButton,
  SouthIcon,
  NorthIcon,
  DeleteIcon,
  EditIcon,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import { useResourceDelete, useResourceMove } from "@tager/admin-services";

import {
  getLocationsCreateUrl,
  getLocationsUpdateUrl,
} from "@/modules/locations";

import {
  deleteDealerLocation,
  moveDealerLocation,
  getLocations,
} from "../requests";
import { DealerLocationInterface } from "../typings";

import { COLUMN_DEFS } from "./LocationsList.helpers";

export default defineComponent({
  name: "LocationsList",
  components: {
    Page,
    DataTable,
    BaseButton,
    SouthIcon,
    NorthIcon,
    DeleteIcon,
    EditIcon,
  },
  setup() {
    const {
      fetchEntityList,
      isLoading: isDataLoading,
      rowData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<DealerLocationInterface>({
      fetchEntityList: (params) =>
        getLocations({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Список локаций",
    });

    const { isMoving, handleResourceMove } = useResourceMove({
      moveResource: moveDealerLocation,
      resourceName: "Локация - Перемещение",
      onSuccess: fetchEntityList,
    });

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deleteDealerLocation,
      resourceName: "Локация - Удаление",
      onSuccess: fetchEntityList,
    });

    function isBusy(id: number): boolean {
      return isDeleting(id) || isMoving(id) || isDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: rowData,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,

      isBusy,

      handleResourceMove,
      handleResourceDelete,

      getLocationsCreateUrl,
      getLocationsUpdateUrl,
    };
  },
});
