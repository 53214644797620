import { RouteRecordRaw } from "vue-router";

import { getLinks } from "@/constants/links";
import DealersList from "@/modules/dealers/DealersList/DealersList.vue";
import DealersCreate from "@/modules/dealers/DealersCreate/DealersCreate.vue";
import DealersUpdate from "@/modules/dealers/DealersUpdate/DealersUpdate.vue";

export const DEALERS_LIST_ROUTE: RouteRecordRaw = {
  path: "/dealers",
  component: DealersList,
  name: "DEALERS List",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().DEALERS],
  },
};

export const DEALERS_CREATE_ROUTE: RouteRecordRaw = {
  path: "/dealers/create",
  component: DealersCreate,
  name: "Dealers Create Form",
  meta: {
    getBreadcrumbs: () => [
      getLinks().HOME,
      getLinks().DEALERS,
      { url: "", text: "Новый дилер" },
    ],
  },
};

export const DEALERS_UPDATE_ROUTE: RouteRecordRaw = {
  path: "/dealers/:id",
  component: DealersUpdate,
  name: "Dealers Update Form",
  meta: {
    getBreadcrumbs: () => [
      getLinks().HOME,
      getLinks().DEALERS,
      { url: "", text: "Дилер" },
    ],
  },
};
