
import { defineComponent } from "vue";

import {
  useDataTable,
  DataTable,
  BaseButton,
  CountButton,
  SouthIcon,
  NorthIcon,
  DeleteIcon,
  EditIcon,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import { useResourceDelete, useResourceMove } from "@tager/admin-services";

import { getDealersCreateUrl, getDealersUpdateUrl } from "@/modules/dealers";
import { getLocationsUrl } from "@/modules/locations";

import { deleteDealer, moveDealer, getDealers } from "../requests";
import { DealerInterface } from "../typings";

import { COLUMN_DEFS } from "./DealersList.helpers";

export default defineComponent({
  name: "DealersList",
  components: {
    Page,
    DataTable,
    BaseButton,
    SouthIcon,
    NorthIcon,
    CountButton,
    DeleteIcon,
    EditIcon,
  },
  setup() {
    const {
      fetchEntityList,
      isLoading: isDataLoading,
      rowData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<DealerInterface>({
      fetchEntityList: (params) =>
        getDealers({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Список дилеров",
    });

    const { isMoving, handleResourceMove } = useResourceMove({
      moveResource: moveDealer,
      resourceName: "Дилер - Перемещение",
      onSuccess: fetchEntityList,
    });

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deleteDealer,
      resourceName: "Дилеры - Удаление",
      onSuccess: fetchEntityList,
    });

    function isBusy(id: number): boolean {
      return isDeleting(id) || isMoving(id) || isDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: rowData,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,

      isBusy,

      handleResourceMove,
      handleResourceDelete,

      getDealersCreateUrl,
      getDealersUpdateUrl,
    };
  },
  methods: { getLocationsUrl },
});
