
import { computed, defineComponent, onMounted } from "vue";

import { NavigationGrid, type NavigationGridItem } from "@tager/admin-ui";
import { useResource } from "@tager/admin-services";

import { getLocationsCount } from "@/modules/locations";
import { getDealersCount } from "@/modules/dealers";
import { getLinks } from "@/constants/links";
import { getReviewsCount } from "@/modules/reviews";

export default defineComponent({
  name: "PageHome",
  components: { NavigationGrid },
  setup() {
    const [
      fetchReviewsCount,
      { data: reviewsCountData, status: reviewsCountDataStatus },
    ] = useResource({
      fetchResource: getReviewsCount,
      initialValue: null,
      resourceName: "Reviews count",
    });
    const [
      fetchDealersCount,
      { data: dealersCountData, status: dealersCountDataStatus },
    ] = useResource({
      fetchResource: getDealersCount,
      initialValue: null,
      resourceName: "Dealers count",
    });

    const [
      fetchLocationsCount,
      {
        data: dealersLocationsCountData,
        status: dealersLocationsCountDataStatus,
      },
    ] = useResource({
      fetchResource: getLocationsCount,
      initialValue: null,
      resourceName: "Dealers Locations count",
    });

    onMounted(() => {
      fetchDealersCount();
      fetchLocationsCount();
      fetchReviewsCount();
    });

    const links = computed(() => getLinks());

    const navItemList = computed<Array<NavigationGridItem>>(() => {
      return [
        {
          name: links.value.DEALERS.text,
          url: links.value.DEALERS.url,
          total: {
            status: dealersCountDataStatus.value,
            value: dealersCountData.value?.count ?? 0,
          },
          width: 33.33333,
        },
        {
          name: links.value.LOCATIONS.text,
          url: links.value.LOCATIONS.url,
          total: {
            status: dealersLocationsCountDataStatus.value,
            value: dealersLocationsCountData.value?.count ?? 0,
          },
          width: 33.33333,
        },
        {
          name: links.value.REVIEWS.text,
          url: links.value.REVIEWS.url,
          total: {
            status: reviewsCountDataStatus.value,
            value: reviewsCountData.value?.count ?? 0,
          },
          width: 33.33333,
        },
        {
          name: "Команды",
          linkList: [
            links.value.CRON_COMMANDS,
            links.value.CRON_COMMANDS_LOGS,
            links.value.CRON_LOGS,
          ],
          width: 50,
        },
        {
          name: "Администраторы",
          linkList: [links.value.ADMIN_ADMINS, links.value.ADMIN_ROLES],
          width: 50,
        },
      ];
    });

    return {
      navItemList,
    };
  },
});
