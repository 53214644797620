
import { computed, defineComponent } from "vue";

import { HomeIcon, SettingsIcon, ViewListIcon } from "@tager/admin-ui";
import { MenuItemType, PageLayout } from "@tager/admin-layout";

import { getLinks } from "@/constants/links";

export default defineComponent({
  name: "BaseApp",
  components: { PageLayout },
  setup() {
    const links = computed(() => getLinks());

    const sidebarMenuList: Array<MenuItemType> = [
      { id: "home", icon: HomeIcon, ...links.value.HOME },
      {
        id: "dealers",
        icon: ViewListIcon,
        ...links.value.DEALERS,
      },
      {
        id: "dealers",
        icon: ViewListIcon,
        ...links.value.LOCATIONS,
      },
      {
        id: "reviews",
        icon: ViewListIcon,
        ...links.value.REVIEWS,
      },
      {
        id: "commands",
        icon: SettingsIcon,
        text: "Команды",
        children: [
          links.value.CRON_COMMANDS,
          links.value.CRON_COMMANDS_LOGS,
          links.value.CRON_LOGS,
        ],
      },
      {
        id: "admins",
        text: "Администраторы",
        icon: SettingsIcon,
        children: [links.value.ADMIN_ADMINS, links.value.ADMIN_ROLES],
      },
    ];

    return {
      sidebarMenuList,
    };
  },
});
