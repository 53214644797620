import { request, ResponseBody } from "@tager/admin-services";

import { ReviewInterface } from "./typings";

export interface CountData {
  count: number;
}

export function getReviewsCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/reviews/count" });
}

export function getReviews(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<ReviewInterface>>> {
  return request.get({ path: "/admin/reviews", params });
}
