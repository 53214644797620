import { RouteRecordRaw } from "vue-router";

import { getLinks } from "@/constants/links";
import LocationsList from "@/modules/locations/LocationsList/LocationsList.vue";
import LocationsCreate from "@/modules/locations/LocationsCreate/LocationsCreate.vue";
import LocationsUpdate from "@/modules/locations/LocationsUpdate/LocationsUpdate.vue";

export const LOCATIONS_LIST_ROUTE: RouteRecordRaw = {
  path: "/locations",
  component: LocationsList,
  name: "Dealers Locations List",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().DEALERS],
  },
};

export const LOCATIONS_CREATE_ROUTE: RouteRecordRaw = {
  path: "/locations/create",
  component: LocationsCreate,
  name: "Dealers Locations Create Form",
  meta: {
    getBreadcrumbs: () => [
      getLinks().HOME,
      getLinks().LOCATIONS,
      { url: "", text: "Новая локация" },
    ],
  },
};

export const LOCATIONS_UPDATE_ROUTE: RouteRecordRaw = {
  path: "/locations/:id",
  component: LocationsUpdate,
  name: "Dealers Locations Update Form",
  meta: {
    getBreadcrumbs: () => [
      getLinks().HOME,
      getLinks().LOCATIONS,
      { url: "", text: "Локация" },
    ],
  },
};
