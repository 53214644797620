
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  FormFooter,
  TagerFormSubmitEvent,
  FormField,
  OptionType,
  FormFieldSelect,
} from "@tager/admin-ui";

import { getDealersUpdateUrl, getDealersUrl } from "@/modules/dealers";

import { createDealer, getApiDealers } from "../requests";

import {
  FormValues,
  convertPageFormValuesToCreationPayload,
  getDefaultFormValues,
} from "./DealersCreate.helpers";

export default defineComponent({
  name: "DealersCreate",
  components: { Page, FormField, FormFooter, FormFieldSelect },
  setup() {
    const router = useRouter();
    const toast = useToast();

    const [
      fetchApiDealersList,
      { data: apiDealers, loading: isApiDealersLoading },
    ] = useResource({
      fetchResource: getApiDealers,
      initialValue: [],
      resourceName: "Api Dealers",
    });

    const apiDealersOptions = computed<Array<OptionType<number>>>(() => {
      return [
        ...apiDealers.value.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      ];
    });

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>(getDefaultFormValues());

    onMounted(() => {
      fetchApiDealersList();
      values.value = getDefaultFormValues();
    });

    const isSubmitting = ref<boolean>(false);

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const requestPromise = createDealer(
        convertPageFormValuesToCreationPayload(values.value)
      );

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getDealersUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getDealersUrl());
          }

          if (event.type === "create_create-another") {
            values.value = getDefaultFormValues();
          }

          toast.show({
            variant: "success",
            title: "Дилеры",
            body: "Дилер успешно создан",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Дилеры",
            body: "Ошибка добавления Дилера",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isLoading = computed<boolean>(() => isApiDealersLoading.value);

    return {
      isLoading,
      values,
      errors,
      submitForm,
      backButtonUrl: getDealersUrl(),
      isSubmitting,
      apiDealersOptions,
    };
  },
});
