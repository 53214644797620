import { OptionType, SingleFileInputValueType } from "@tager/admin-ui";
import { createId, Nullable } from "@tager/admin-services";
import { FieldConfigUnion, RepeaterField } from "@tager/admin-dynamic-field";

import { DealerLocationFullInterface } from "@/modules/locations/typings";
import { DealerLocationUpdatePayload } from "@/modules/locations/requests";

export type FormValues = {
  name: string;
  apiId: Nullable<OptionType<number>>;
  dealer: Nullable<OptionType<number>>;
  dealerCrm: Nullable<OptionType<number>>;
  brands: Array<OptionType<number>>;
  image: Nullable<SingleFileInputValueType>;
  website: string | null;
  phone: string | null;
  phoneStock: string | null;
  address: string | null;
  latitude: number | null;
  longitude: number | null;
  instagram: string | null;
  odnoklassniki: string | null;
  youtube: string | null;
  facebook: string | null;
  vkontakte: string | null;
  telegram: string | null;
  viber: string | null;
  skype: string | null;
  tiktok: string | null;
};

export function getDealerFormValue(
  dealer: DealerLocationFullInterface,
  dealerOptions: Array<OptionType<number>>
): FormValues {
  return {
    name: dealer.name || "",
    dealer:
      dealerOptions.find((item) => item.value === dealer.dealer?.id) || null,

    dealerCrm:
      dealerOptions.find((item) => item.value === dealer.dealerCrm?.id) || null,

    apiId: null,
    brands: [],

    image: dealer.image
      ? {
          id: createId(),
          file: dealer.image,
          caption: "",
        }
      : null,

    website: dealer.website,
    phone: dealer.phone,
    phoneStock: dealer.phoneStock,
    address: dealer.address,
    latitude: dealer.latitude,
    longitude: dealer.longitude,
    instagram: dealer.instagram,
    odnoklassniki: dealer.odnoklassniki,
    youtube: dealer.youtube,
    facebook: dealer.facebook,
    vkontakte: dealer.vkontakte,
    telegram: dealer.telegram,
    viber: dealer.viber,
    skype: dealer.skype,
    tiktok: dealer.tiktok,
  };
}

export function convertPageFormValuesToUpdatePayload(
  values: FormValues,
  divisionsField: RepeaterField
): DealerLocationUpdatePayload {
  return {
    apiId: values.apiId?.value || null,
    brandIds: values.brands.map((item) => item.value),
    name: values.name,
    dealer: values.dealer?.value || null,
    dealerCrm: values.dealerCrm?.value || null,
    image: values.image?.file?.id || null,
    website: values.website,
    phone: values.phone,
    phoneStock: values.phoneStock,
    address: values.address,
    latitude: values.latitude,
    longitude: values.longitude,
    instagram: values.instagram,
    odnoklassniki: values.odnoklassniki,
    youtube: values.youtube,
    facebook: values.facebook,
    vkontakte: values.vkontakte,
    telegram: values.telegram,
    viber: values.viber,
    skype: values.skype,
    tiktok: values.tiktok,

    divisions: divisionsField.value.map((item) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const image = item.value[1].value?.file.id || null;

      return {
        name: item.value[0].value?.toString() || "",
        image,
        phone: item.value[2].value?.toString() || "",
        workTime: item.value[3].value?.toString() || "",
      };
    }),
  };
}

export const DIVISIONS_FIELD_REPEATER_CONFIG: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Подразделения",
  meta: {
    view: "BLOCK",
    noToggle: true,
    isEmptyHidden: true,
    titleFormatter: (values) => {
      return String(values[0].value) || "Подразделение";
    },
  },
  fields: [
    {
      name: "name",
      type: "STRING",
      label: "Название",
    },
    {
      name: "image",
      type: "IMAGE",
      label: "Картинка",
      meta: {
        scenario: "dealer-location-department-image",
      },
    },
    {
      name: "phone",
      type: "STRING",
      label: "Телефон",
    },
    {
      name: "workTime",
      type: "TEXT",
      label: "Режим работы",
    },
  ],
};
