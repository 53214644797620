import { OptionType } from "@tager/admin-ui";
import { Nullable } from "@tager/admin-services";

import { DealerLocationCreatePayload } from "@/modules/locations/requests";

export type FormValues = {
  name: string;
  dealer: Nullable<OptionType<number>>;
  apiId: Nullable<OptionType<number>>;
};

export function getDefaultFormValues(): FormValues {
  return {
    name: "",
    dealer: null,
    apiId: null,
  };
}

export function convertPageFormValuesToCreationPayload(
  values: FormValues
): DealerLocationCreatePayload {
  return {
    name: values.name,
    dealer: values.dealer?.value || null,
    apiId: values.apiId?.value || null,
  };
}
