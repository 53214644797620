import { RouteRecordRaw } from "vue-router";

import { getLinks } from "@/constants/links";
import ReviewsList from "@/modules/reviews/ReviewsList/ReviewsList.vue";

export const REVIEWS_LIST_ROUTE: RouteRecordRaw = {
  path: "/reviews",
  component: ReviewsList,
  name: "Reviews List",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().REVIEWS],
  },
};
