
import { defineComponent } from "vue";

import { useDataTable, DataTable } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { getReviews } from "../requests";
import { ReviewInterface } from "../typings";

import { COLUMN_DEFS } from "./ReviewsList.helpers";

export default defineComponent({
  name: "ReviewsList",
  components: {
    Page,
    DataTable,
  },
  setup() {
    const {
      isLoading: isDataLoading,
      rowData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<ReviewInterface>({
      fetchEntityList: (params) =>
        getReviews({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Список отзывов",
    });

    function isBusy(id: number): boolean {
      return isDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: rowData,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,

      isBusy,
    };
  },
});
