
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  FormFooter,
  TagerFormSubmitEvent,
  FormField,
  FormFieldRichTextInput,
  OptionType,
  FormFieldSelect,
  FormFieldMultiSelect,
  TabType,
  createTabErrorFinder,
  FormFieldOptionsSwitcherTrueFalse,
  FormFieldUrlAliasInput,
  FormFieldFileInput,
  FormFieldOptionsSwitcher,
} from "@tager/admin-ui";

import {
  getApiDealers,
  getBrands,
  getDealer,
  getDealersUpdateUrl,
  getDealersUrl,
  updateDealer,
} from "@/modules/dealers";
import {
  DealerFullInterface,
  DealerTypeOptions,
} from "@/modules/dealers/typings";

import {
  FormValues,
  getDealerFormValue,
  convertPageFormValuesToUpdatePayload,
} from "./DealersUpdate.helpers";

export default defineComponent({
  name: "DealersUpdate",
  components: {
    FormFieldSelect,
    FormFieldOptionsSwitcher,
    FormFieldMultiSelect,
    Page,
    FormField,
    FormFooter,
    FormFieldOptionsSwitcherTrueFalse,
    FormFieldUrlAliasInput,
    FormFieldFileInput,
    FormFieldRichTextInput,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const id = computed(() => route.params.id as string);

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () => {
        if (id.value) {
          return getDealer(id.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "Dealer",
    });

    const [
      fetchApiDealers,
      { data: apiDealers, loading: isApiDealersLoading },
    ] = useResource({
      fetchResource: getApiDealers,
      initialValue: [],
      resourceName: "Api Dealers",
    });

    const [fetchBrands, { data: brands, loading: isBrandsLoading }] =
      useResource({
        fetchResource: getBrands,
        initialValue: [],
        resourceName: "Brands",
      });

    onMounted(() => {
      fetchApiDealers();
      fetchBrands();
      fetchModel();
    });
    watch(id, fetchModel);

    const apiDealersOptions = computed<Array<OptionType<number>>>(() => {
      return [
        ...apiDealers.value.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      ];
    });

    const brandsOptions = computed<Array<OptionType<number>>>(() => {
      return [
        ...brands.value.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      ];
    });

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>();

    const isSubmitting = ref<boolean>(false);

    function updateFormValues() {
      values.value = getDealerFormValue(
        model.value as DealerFullInterface,
        apiDealersOptions.value,
        brandsOptions.value
      );
    }

    watch([model, apiDealersOptions, brandsOptions], () => {
      if (model.value) {
        updateFormValues();
      }
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const requestPromise = updateDealer(
        id.value,
        convertPageFormValuesToUpdatePayload(values.value as FormValues)
      );

      requestPromise
        .then((response: any) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getDealersUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getDealersUrl());
          }
          toast.show({
            variant: "success",
            title: "Дилеры",
            body: "Дилер успешно обновлен",
          });
        })
        .catch((error: any) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Дилеры",
            body: "Ошибка изменения дилера",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isLoading = computed<boolean>(
      () =>
        isModelLoading.value ||
        isApiDealersLoading.value ||
        isBrandsLoading.value
    );

    const tabList = computed<Array<TabType>>(() => {
      const hasErrors = createTabErrorFinder(errors.value);

      return [
        {
          id: "common",
          label: "Основное",
          hasErrors: hasErrors(["apiDealer", "brands", "name"]),
        },
        {
          id: "website",
          label: "Страница на сайте",
        },
      ];
    });

    const selectedTabId = ref<string>(tabList.value[0].id);

    return {
      id,
      isLoading,
      values,
      errors,
      model,
      submitForm,
      backButtonUrl: getDealersUrl(),
      isSubmitting,
      apiDealersOptions,
      brandsOptions,

      tabList,
      selectedTabId,
      typeOptions: DealerTypeOptions,
    };
  },
});
