import { ColumnDefinition } from "@tager/admin-ui";

import { getDealersUpdateUrl } from "@/modules/dealers";

import { DealerInterface, DealerTypeOptions } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<DealerInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: { width: "50px", textAlign: "center" },
    headStyle: { width: "50px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "API ID",
    field: "apiId",
    style: { width: "70px", textAlign: "center" },
    headStyle: { width: "70px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 3,
    name: "Название",
    field: "name",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: row.name,
        url: getDealersUpdateUrl(row.id),
      },
      websiteLink: {
        text: row.websiteUrl,
        url: row.websiteUrl,
      },
      paramList: [
        {
          name: "Тип",
          value:
            DealerTypeOptions.find((item) => item.value === row.type)?.label ??
            "",
        },
      ],
    }),
  },
  {
    id: 4,
    name: "Картинка",
    field: "image",
    type: "image",
    width: "200px",
  },
  {
    id: 5,
    name: "Рейтинг",
    field: "rating",
    style: { width: "70px", textAlign: "center" },
    headStyle: { width: "70px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 6,
    name: "",
    field: "relations",
    width: "350px",
  },
  {
    id: 5,
    name: "Действия",
    field: "actions",
    style: { width: "180px", textAlign: "center" },
    headStyle: { width: "180px", textAlign: "center", whiteSpace: "nowrap" },
  },
];
