import { Nullable } from "@tager/admin-services";
import { OptionType } from "@tager/admin-ui";

import { DealerCreatePayload } from "@/modules/dealers/requests";

export type FormValues = {
  apiDealer: Nullable<OptionType<number>>;
  name: string;
};

export function getDefaultFormValues(): FormValues {
  return {
    name: "",
    apiDealer: null,
  };
}

export function convertPageFormValuesToCreationPayload(
  values: FormValues
): DealerCreatePayload {
  return {
    name: values.name,
    apiDealer: values.apiDealer?.value || null,
  };
}
