import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relations" }
const _hoisted_2 = { class: "relations-item" }
const _hoisted_3 = { class: "relations-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CountButton = _resolveComponent("CountButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SouthIcon = _resolveComponent("SouthIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_NorthIcon = _resolveComponent("NorthIcon")!
  const _component_EditIcon = _resolveComponent("EditIcon")!
  const _component_DeleteIcon = _resolveComponent("DeleteIcon")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: "Дилеры",
    "header-buttons": [
      {
        text: 'Создать дилера',
        href: _ctx.getDealersCreateUrl(),
      },
    ]
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DataTable, {
        "column-defs": _ctx.columnDefs,
        "row-data": _ctx.rowData,
        loading: _ctx.isRowDataLoading,
        "error-message": _ctx.errorMessage,
        "search-query": _ctx.searchQuery,
        pagination: {
        pageNumber: _ctx.pageNumber,
        pageCount: _ctx.pageCount,
        pageSize: _ctx.pageSize,
        disabled: _ctx.isRowDataLoading,
      },
        onChange: _ctx.handleChange
      }, {
        "cell(relations)": _withCtx(({ row }) => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_router_link, {
                to: {
                path: _ctx.getLocationsUrl(),
                query: { 'filter[dealer]': row.id },
              }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_CountButton, {
                    variant: "outline-secondary",
                    count: row.locationsCount,
                    class: "margin-right"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Локации ")
                    ]),
                    _: 2
                  }, 1032, ["count"])
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_router_link, {
                to: {
                path: '/reviews',
                query: { 'filter[dealer]': row.id },
              }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_CountButton, {
                    variant: "outline-secondary",
                    count: row.reviewsCount,
                    class: "margin-right"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Отзывы ")
                    ]),
                    _: 2
                  }, 1032, ["count"])
                ]),
                _: 2
              }, 1032, ["to"])
            ])
          ])
        ]),
        "cell(actions)": _withCtx(({ row, rowIndex }) => [
          _createVNode(_component_BaseButton, {
            variant: "icon",
            disabled: _ctx.isBusy(row.id) || rowIndex === _ctx.rowData.length - 1,
            onClick: ($event: any) => (_ctx.handleResourceMove(row.id, 'down'))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SouthIcon)
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"]),
          _createVNode(_component_BaseButton, {
            variant: "icon",
            disabled: _ctx.isBusy(row.id) || rowIndex === 0,
            onClick: ($event: any) => (_ctx.handleResourceMove(row.id, 'up'))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NorthIcon)
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"]),
          _createVNode(_component_BaseButton, {
            variant: "icon",
            title: "Редактировать",
            disabled: _ctx.isBusy(row.id),
            href: _ctx.getDealersUpdateUrl(row.id)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_EditIcon)
            ]),
            _: 2
          }, 1032, ["disabled", "href"]),
          _createVNode(_component_BaseButton, {
            variant: "icon",
            title: "Удалить",
            disabled: _ctx.isBusy(row.id) || row.canDelete === false,
            onClick: ($event: any) => (_ctx.handleResourceDelete(row.id))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DeleteIcon)
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"])
        ]),
        _: 1
      }, 8, ["column-defs", "row-data", "loading", "error-message", "search-query", "pagination", "onChange"])
    ]),
    _: 1
  }, 8, ["header-buttons"]))
}